; (function ($) {
  // ---------- APP START
  window.App = {

    config: {
      scrollOffset: 50
    },

    windowWidth: $(window).width(),
    bpScroll: 45,

    init: function () {
      // Init Perfect Scrollbar
      document.querySelectorAll('.l-scrollbar').forEach(function ($scrollbar) {
        new PerfectScrollbar($scrollbar, {
          suppressScrollX: true
        });
      });

      $('a[href^="#"]').on('click', function (e) {
        e.preventDefault();

        var scrollTo = $(this).data('scroll-to') ? $(this).data('scroll-to') : $(this).attr('href');

        if ($(scrollTo).length > 0 && $(this).data('prevent-scroll') !== true)
          App.scroll(scrollTo, $(this).data('scroll-offset'));
      });

      if ($('[data-scroll]').length) {
        App.scroll($('[data-scroll]').data('scroll'));
      }

      App.initMenu();
      App.initMap();
      App.initVideo();
      App.initPopup();
      App.initImg();
      App.initLandingPageForm();
      App.initLandingPageTel();
      App.offCanvas();
      App.initTracking();
      App.getHeights();
      if ($('.slider'.length)) App.initSlider();
      if ('.pg__section.table') App.dragableTable();

    },

    load: function () {

      if ($('.iframe__wrapper').length) {
        var $iframe = $('.iframe__wrapper').find('iframe').get(0);
        iFrameResize({ log: false, heightCalculationMethod: 'taggedElement' }, $iframe);
      }
    },

    scroll: function (element, offset) {
      offset = offset === undefined ? App.config.scrollOffset : offset;
      $('html, body').animate({ scrollTop: $(element).offset().top - offset }, 'slow');
    },

    initMenu: function () {
      // Toggle menu
      $('.js-menu-toggle').on('click', function () {
        $('body').toggleClass('is-nav-open');
        $('.js-header-nav').toggleClass('is-open');
      });

      $('.js-menu-toggle2').on('click', function () {
        $('body').toggleClass('is-nav-open');
        $('.js-header-nav').toggleClass('is-open');
      });

      // Toggle submenus
      var $menu = document.querySelector('.js-menu');
      var $submenuTogglers = document.querySelectorAll('.js-submenu-toggler');

      if ($submenuTogglers.length) {

        $submenuTogglers.forEach(function ($toggler) {

          var $togglerLink = $toggler.firstElementChild;
          var $togglerIcon = $togglerLink.querySelector('i');
          var $submenuContainer = $toggler.querySelector('.js-submenu-container');
          var menuHeight = ($toggler.closest('.js-submenu-container')) ? $toggler.closest('.js-submenu-container').offsetHeight : $menu.offsetHeight;

          // Add submenu btn
          var $submenuBtn = document.createElement('button');
          $submenuBtn.textContent = $togglerLink.textContent;
          $submenuBtn.setAttribute('class', 'submenu-btn');
          $submenuContainer.prepend($submenuBtn);

          // Open submenu
          $togglerLink.addEventListener('click', function (e) {
            e.preventDefault();
            $submenuContainer.classList.add('is-opened');
            $menu.style.height = $submenuContainer.offsetHeight + 'px';
            $menu.classList.add('is-submenu-opened');
          });

          // Close submenu
          $submenuBtn.addEventListener('click', function () {
            $submenuContainer.classList.remove('is-opened');
            $menu.style.height = menuHeight + 'px';
          });

          /*if($(this).hasClass('m--has-children')) {
            $(this).on('mouseover', function() {
              console.log('mouseover submenuTogglers');
            });
          }*/
        });
      }
    },

    initMap: function () {
      var $maps = document.querySelectorAll('.js-map');
      if ($maps.length) {
        $maps.forEach(function ($map) {

          var $mapView = $map.querySelector('.js-map-view');
          var $mapLocations = $map.querySelectorAll('.js-map-location');
          var markers = [];
          var i = 1;
          var map = L.map($mapView, {
            center: [48.18922, -2.91275],
            // zoom: 10,
            // minZoom: 8,
            maxZoom: 16,
            scrollWheelZoom: false,
            dragging: !L.Browser.mobile
          });

          L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png').addTo(map);

          var icon = L.icon({
            iconUrl: '/assets/images/layout/pin.svg',
            iconSize: [30, 34],
            iconAnchor: [15, 34],
          });

          var overicon = L.icon({
            iconUrl: '/assets/images/layout/pinover.svg',
            iconSize: [30, 34],
            iconAnchor: [15, 34],
          });

          $mapLocations.forEach(function ($location) {
            var marker = L.marker([$location.getAttribute('data-lat'), $location.getAttribute('data-long')], {
              icon: icon,
              title: $location.getAttribute('data-title')
            }).addTo(map);
            marker.on('mouseover', function (ev) {
              marker.setIcon(overicon);
            });
            marker.on('mouseout', function (ev) {
              marker.setIcon(icon);
            });
            if ($location.getAttribute('data-url')) {
              marker.on('click', function (ev) {
                document.location.href = $location.getAttribute('data-url');
              });
            }
            markers.push(marker);
            i++;
          });

          var refit = function (map, markers) {
            var group = new L.featureGroup(markers);
            map.fitBounds(group.getBounds(), { padding: [10, 10], maxZoom: 10 });
          };

          refit(map, markers);
          window.addEventListener('resize', function () {
            refit(map, markers);
          });
        });
      }
    },

    initVideo: function () {
      $('.js-video').magnificPopup({
        type: 'iframe',
        preloader: false,
      });
    },

    initPopup: function () {
      $('.js-photo').magnificPopup({
        type: 'image',
        preloader: false,
      });
    },

    initImg: function () {
      $('.js-popup').magnificPopup({
        type: 'inline',
        preloader: false,
      });
    },

    initLandingPageForm: function () {
      var formLayerSelector = '#lp-form-layer';
      var formWrapperSelector = '#lp-form-wrapper';
      var formSelector = '#lp-form';
      var triggerSelector = '#lp-form-trigger';
      var closeSelector = '#lp-form-close';
      var tokenSelector = '#lp-form-token';

      $(tokenSelector).val($('meta[name="refresh"]').attr('content'));

      $(triggerSelector).on('click', function () {
        $('body').addClass('max-1280:overflow-hidden');
        $(formLayerSelector).addClass('is-open');
      });
      $(closeSelector).on('click', function () {
        $('body').removeClass('max-1280:overflow-hidden');
        $(formLayerSelector).removeClass('is-open');
      });

      $(formSelector).on('submit', handleFormSubmit);

      function handleFormSubmit (event) {
        $.ajax({
          url: '',
          type: 'POST',
          data: $(event.target).serialize(),
          success: function (res) {
            var parser = new DOMParser();
            var resHTML = parser.parseFromString(res, 'text/html');
            $(formWrapperSelector).html(resHTML.querySelector(formWrapperSelector).innerHTML);
            var $success = $('[data-form-success]');
            if ($success.length) {
              var params = $success.data('params');
              App.conversionLaunch(params.type, {
                'event_category': 'lp-form-submit',
                'event_label': params.agency,
              });
              return;
            }
            $(formSelector).on('submit', handleFormSubmit);
          },
        });

        event.preventDefault();
      }
    },

    initLandingPageTel: function () {
      var triggerSelector = '#lp-tel-trigger';
      var tooltipSelector = '#lp-tel-tooltip';
      var triggerEl = document.querySelector(triggerSelector);
      var tooltipEl = document.querySelector(tooltipSelector);
      var visible = false;
      var cleanup;

      $(document).on('click', function (event) {
        if (!$(event.target).closest(tooltipSelector).length && visible) {
          $(tooltipSelector).hide();
          visible = false;
          cleanup();
        }
      });

      $(triggerSelector).on('click', function (event) {
        $(tooltipSelector).toggle();
        visible = !visible;
        if (visible) {
          computePosition();
        } else {
          cleanup();
        }
        event.stopPropagation();
      });

      function computePosition () {
        cleanup = FloatingUIDOM.autoUpdate(triggerEl, tooltipEl, function () {
          const desktop = window.innerWidth >= 1280;
          FloatingUIDOM.computePosition(triggerEl, tooltipEl, {
            placement: 'bottom',
            strategy: desktop ? 'absolute' : 'fixed',
            middleware: [
              FloatingUIDOM.offset(20),
              FloatingUIDOM.shift({ padding: desktop ? 48 : 20 }),
              FloatingUIDOM.flip(),
            ],
          }).then(({ x, y }) => {
            Object.assign(tooltipEl.style, {
              left: `${x}px`,
              top: `${y}px`,
            });
          });
        });
      }
    },

    offCanvas: function () {
      var bodyElement = document.querySelector('[body]');
      var offCanvasElements = document.querySelectorAll('[offCanvas]');
      var offCanvasCloseElement = document.querySelector('[offcanvasclose]');
      var offCanvasModalElement = document.querySelector('[offcanvasmodal]');
      var offCanvasBackdropElement = document.querySelector('[offcanvasbackdrop]');

      var target;

      if (!offCanvasCloseElement) return;

      offCanvasCloseElement.addEventListener('click', function () {
        if (!!offCanvasCloseElement) {
          offCanvasClose();
        }
      });

      offCanvasBackdropElement.addEventListener('click', function () {
        if (!!offCanvasCloseElement) {
          offCanvasClose();
        }
      });

      [].forEach.call(offCanvasElements, function (el) {
        if (!!el) {
          el.addEventListener('click', function () {
            bodyElement.style.overflow = "hidden";
            target = this.getAttribute("offCanvasTarget");
            var targetElement = document.querySelector('#' + target);
            if (!!targetElement) {
              teleportElement('#' + target, '[offCanvasModal]');
              offCanvasModalElement.classList.add('offCanvas_active');
              offCanvasModalElement.style.setProperty('transform', 'translateX(0%)');
              offCanvasBackdropElement.classList.add('offCanvas__backdrop_active');
              offCanvasModalElement.setAttribute('aria-modal', 'true');
              offCanvasModalElement.setAttribute('role', 'modal');

              if (!!offCanvasModalElement.getAttribute("aria-hidden")) {
                offCanvasModalElement.removeAttribute('aria-hidden');
              }
            }
          });
        }
      });

      function offCanvasClose() {
        offCanvasModalElement.style.setProperty('transform', 'translateX(100%)');

        setTimeout(function () {
          offCanvasModalElement.classList.remove('offCanvas_active');
          offCanvasBackdropElement.classList.remove('offCanvas__backdrop_active');
          offCanvasModalElement.removeAttribute('aria-modal');
          offCanvasModalElement.removeAttribute('role');
          offCanvasModalElement.setAttribute('aria-hidden', 'true');
          bodyElement.style.overflow = "visible";
          teleportElement('[offcanvascontent]', '[offcanvastarget = ' + target + ']');
        }, 300);
      }
    },
    /* Sliders
    ----------------------------------*/
    initSlider: function () {
      var $sliderNav = $('.slider__nav');
      var $slider = $('.slider');
      var $cols;

      if (!!document.querySelector('[slider-news]')) {
        $cols = 3;
      } else if (!!document.querySelector('[related-articles-slider]')) {
        $cols = 2;
      } else {
        $cols = 2.2;
      }
      $('.slider').slick({
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: $cols,
        slidesToScroll: 2,
        autoplay: false,
        responsive: [
          {
            breakpoint: 1156,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              arrows: false,
            }
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });
    },

    /* Make dragable table for mobile
    ----------------------------------*/
    dragableTable: function () {

      if ($(window).width() < 414) {
        $('.table-wrapper').each(function () {
          $(this).draggable({ axis: 'x', containment: 'window' });
        });
      }
    },

    /* Matomo
    ----------------------------------*/
    conversionLaunch: function (action, params) {
      if (window._paq != undefined) {
        _paq.push(['trackEvent', params.event_category, action, params.event_label]);
      }
    },

    initTracking: function () {
      $('.js-cta-call').on('click', function () {
        var phoneNumber = $('.js-cta-call-number').text();
        App.conversionLaunch('clic-generique-tel', {
          'event_category': 'clic-contact',
          'event_label': phoneNumber
        });
      });

      $('.js-agency-phone').on('click', function () {
        var phoneNumber = $(this).text();
        App.conversionLaunch('clic-agence-tel', {
          'event_category': 'clic-contact',
          'event_label': phoneNumber
        });
      });

      $('.js-agency-email').on('click', function () {
        var emailAddress = $(this).text();
        App.conversionLaunch('clic-agence-email', {
          'event_category': 'clic-contact',
          'event_label': emailAddress
        });
      });

      $('.js-agency-itinerary').on('click', function () {
        var agencyName = $(this).data('title');
        App.conversionLaunch('clic-agence-itineraire', {
          'event_category': 'clic-contact',
          'event_label': agencyName
        });
      });

      $('.js-cta-quotation-header').on('click', function () {
        App.conversionLaunch('clic-devis', {
          'event_category': 'clic-CTA',
          'event_label': 'demander un devis-header'
        });
      });

      $('.js-cta-quotation-product').on('click', function () {
        var productName = $(this).data('title');
        App.conversionLaunch('clic-devis', {
          'event_category': 'clic-CTA',
          'event_label': 'demander un devis-' + productName
        });
      });

      $('.js-cta-prendre-devis').on('click', function () {
        App.conversionLaunch('clic-prendre-rdv', {
          'event_category': 'clic-CTA',
          'event_label': 'prendre un rdv-footer'
        });
      });

      $('.js-cta-prendre-devis-header').on('click', function () {
        App.conversionLaunch('clic-prendre-rdv', {
          'event_category': 'clic-CTA',
          'event_label': 'prendre un rdv-header'
        });
      });
    },

    initForms: function () {
      $('.sv-form [name=token]').val($('meta[name=refresh]').attr('content'));
    },

    getHeights: function () {
      var vh = window.innerHeight * 0.01;
      $(':root').css({
        '--vh': vh + 'px',
        '--heightHeader': $('.header-inner').outerHeight() + 'px',
      });
    },
  };
  // ---------- APP END

  // Init app
  $(document).ready(App.init);
  $(window).on('load', App.load); // jQuery 3
  $(window).resize(App.resize);
})(jQuery);

function teleportElement(selectorToBeMoved, selectorOnWhichToAppendChild) {
  var elementOnWhichToAppendChild = document.querySelector(selectorOnWhichToAppendChild);

  [].forEach.call(document.querySelectorAll(selectorToBeMoved), function (el) {
    elementOnWhichToAppendChild.appendChild(el);
  });
}

function getHeight(elm) {
  elm.forEach(function (element) {
    if (!!document.querySelector(element)) {
      var height = document.querySelector(element).offsetHeight;
      var noSpecialChars = element.replace(/[^a-zA-Z0-9 ]/g, '');
      document.querySelector(':root').style.setProperty('--' + noSpecialChars + 'Height', height + 'px');
    }
  });
}

function getWidth(elm) {
  elm.forEach(function (element) {
    if (!!document.querySelector(element)) {
      var width = document.querySelector(element).offsetWidth;
      var noSpecialChars = element.replace(/[^a-zA-Z0-9 ]/g, '');
      document.querySelector(':root').style.setProperty('--' + noSpecialChars + 'Width', width + 'px');
    }
  });
}

var ArrayHeightOfElements = ['[diaporama__button]', '[diaporama__pagination]', '[header]', '[l-wrapper-frame]'];
window.addEventListener(
  "load", function () {
    getHeight(ArrayHeightOfElements);
    getWidth(['[diaporama__button]']);
    initSwiper();
    initNetReviewsSwiper();
  }
);

window.addEventListener(
  "resize", function () {
    getHeight(ArrayHeightOfElements);
  }
);

function initSwiper() {
  var swiper = new Swiper("[homeSlider]", {
    speed: 800,
    stopOnLastSlide: true,
    spaceBetween: 30,
    // autoplay: {
    //   delay: 4000,
    //   disableOnInteraction: false,
    //   pauseOnMouseEnter: true,
    // },
    pagination: {
      clickable: true,
      el: "[diaporama__pagination]",
      bulletClass: "diaporama__bullet",
      bulletActiveClass: "diaporama__bullet_active"
    },
  });
}

function initNetReviewsSwiper() {
  var netReviewsSwiper = new Swiper("[netReviewsSlider]", {
    speed: 1000,
    slidesPerView: 1,
    spaceBetween: 20,
    watchOverflow: true,
    breakpoints: {
      1600: {
        slidesPerView: 2,
      },
      1366: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 2,
      },
      640: {
        slidesPerView: 2,
      }
    },
    navigation: {
      nextEl: "#netReviewsSliderNext",
      prevEl: "#netReviewsSliderPrev"
    },
  });
}

window.addEventListener(
  "load", function () {
    if (!!document.querySelector('[realisationsSwiper]')) {
      var initRealisations = false;
      var realisations_swiper = null;

      realisationsResponsive();
      window.addEventListener("resize", realisationsResponsive);
    }

    function realisationsResponsive() {
      if (window.innerWidth <= 1152) {
        if (!initRealisations) {
          initRealisations = true;
          realisations_swiper = new Swiper("[realisationsSwiper]", {
            speed: 800,
            stopOnLastSlide: true,
            spaceBetween: 30,
            // autoplay: {
            //   delay: 4000,
            //   disableOnInteraction: false,
            //   pauseOnMouseEnter: true,
            // },
            navigation: {
              prevEl: "[realisations__prev]",
              nextEl: "[realisations__next]",
            },
            breakpoints: {
              // when window width is >= 320px
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              }
            },
            pagination: {
              el: "[realisations__pagination]",
              type: "fraction",
            },
          });
        }
      } else if (initRealisations) {
        realisations_swiper.destroy();
        initRealisations = false;
      }
    }
  }
);

parent.postMessage({action : 'scrollToTop'}, '*')
window.addEventListener('message', event => {

  const element = document.getElementsByTagName('h1')[0];
  if (event.origin === 'https://projets.monatoutenergie.fr' || event.origin === 'https://demoec.preprod.monatoutenergie.fr') {
    if (event?.data?.action === 'scroll-to-top')
      window.scrollTo({
        top: element,
        behavior: 'smooth'
      })
  }
});
